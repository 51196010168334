const Reducer = (state, action) => {
    switch (action.type) {
        case 'SET_CONTENT':
            return {
                ...state,
                content: action.payload
            };
        case 'SET_MENU':
            return {
                ...state,
                menu: action.payload
            };
        /*case 'SET_AUTH':
        	console.log(action.type);
            return {
                ...state,
                loggedIn: action.payload
            };
        */
        case 'SET_USER':
        	console.log(action.type);
            return {
                ...state,
                loggedIn: true,
                user: action.payload
            };
      /*  case 'ADD_POST':
            return {
                ...state,
                posts: state.posts.concat(action.payload)
            };
        case 'REMOVE_POST':
            return {
                ...state,
                posts: state.posts.filter(post => post.id !== action.payload)
            };
        case 'SET_ERROR':
            return {
                ...state,
                error: action.payload
            };
            */
        default:
            return state;
    }
};

export default Reducer;