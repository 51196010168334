import { Link } from "react-router-dom";
import React, {useEffect, useContext, useState} from 'react';
import api from '../services/api';
import {Context} from '../Store';



const Menu = () => {
	const [state, dispatch] = useContext(Context);
	const [menu, setMenu] = useState([]);
//	const [isActive, setActive] = useState("false");
	const handleToggle = () => {
		document.body.classList.toggle('navigation-is-open');
	//	setActive(!isActive);
	};	
	useEffect(() => {
		api.post('api/menu')
			.then(response => {	
				const menu = {main: response.data.main};
				setMenu(menu.main);
				dispatch({type: 'SET_MENU', payload: menu});
			})
			.catch(error => {
				dispatch({type: 'SET_ERROR', payload: error});
			});	
	
	}, [dispatch]);

	
	
	const toggleTheme = function (e){
		const theme = e.currentTarget.attributes.getNamedItem('theme').value;
		const removeClassByPrefix = function (node, prefix) {
			var regx = new RegExp('\\b' + prefix + '[^ ]*[ ]?\\b', 'g');
			node.className = node.className.replace(regx, '');
			return node;
		}
		removeClassByPrefix(document.body, "theme--");
		
		if(theme){
			document.body.classList.toggle('theme--' + theme);
		}
	}
	const linkAccess = function (link){
		let hasAccess = true;
		switch(link.access){
			case 'isAnonymous':
				if(state.loggedIn) hasAccess = false;
				break; 
			case 'isAuthenticated':
				if(!state.loggedIn) hasAccess = false;
				break;
			default: break;
		}
		
		return hasAccess;
	}
	if(menu && Object.keys(menu).length !== 0){
		menu.map(function (link, index){
			if(!linkAccess(link)){
				delete menu[index];
				return false;
			}
			return true;
		})
	};	
	if (!state.error && menu && Object.keys(menu).length !== 0) {
		return (
			<div className="navigation">
				<div className="topbar">
					<button onClick={handleToggle} className="hamburger hamburger--slider" type="button" aria-label="Menu" aria-controls="navigation">
					  <span className="hamburger-box">
					    <span className="hamburger-inner"></span>
					  </span>
					</button>
				</div>
				<div className="wrapper">
				<nav>
					<ul className="nav">
					{menu.map((link, index) => (
						<li key={index}><Link onClick={handleToggle} to={link.to}>{link.label}</Link></li>
			        ))}
			        <li><a href="#" onClick={toggleTheme} theme="">Theme Default</a></li>
			        <li><a href="#" onClick={toggleTheme} theme="light">Theme Light</a></li>
			        <li><a href="#" onClick={toggleTheme} theme="dark">Theme Dark</a></li>
			        </ul>
				</nav>
				</div>
			</div>
		);
	}
	return <></>
}

export default Menu;