import { useLocation, useHistory } from "react-router-dom";
import React, {useEffect, Suspense, useContext} from 'react';
import api from '../services/api';
import {Context} from '../Store';
import Loader from './Loader';
const Form = React.lazy(() => import('./form.api'));


const ContentController = () => {
//function ContentController() {
	const location = useLocation();
	const history = useHistory();
	const [state, dispatch] = useContext(Context);
	useEffect(() => {
		api.post('api/content', {alias:location.pathname})
			.then(response => {	
				const content = response.data;
				//console.log(content);
				if(content){
					dispatch({type: 'SET_CONTENT', payload: content});
				}	
			})
			.catch(error => {
				if (error.response) {
					dispatch({type: 'SET_CONTENT', payload: error.response.data});
			    }
				//dispatch({type: 'SET_ERROR', payload: error});
			});	
	}, [location, dispatch]);
	if (!state.error && state.content) {
		if(state.content.access){
			let redirect = '';
			switch(state.content.access){
				case 'isAnonymous':
					if(state.loggedIn) redirect = state.content.redirect ? state.content.redirect : '/dashboard';
					break; 
				case 'isAuthenticated':
					if(!state.loggedIn) redirect = state.content.redirect ? state.content.redirect : '/dashboard';
					break;
				default: break;
			}
			if(redirect !== ''){
				dispatch({type: 'SET_CONTENT', payload: {}});
				history.push(redirect);
			}						
		}		
		return (
			<div className="page-wrapper">
				<Suspense fallback={<Loader />}>
					{(() => {
						let component = (<></>);
						switch(state.content.type) {
							case 'form':
								let props = state.content.data;
								component = (
									<div className="card">
										<h1>{state.content.data.label}</h1>
										<h2>{state.content.data.description}</h2>
										<Form {...props} />
									</div>
									)
								break;
							case 'markup':
									component = (
										<div dangerouslySetInnerHTML={{ __html: state.content.markup }} ></div>
									)
								break
							default:
							  component = <Loader />
						}
						return component;
					})()}
				</Suspense>
			</div>
		);
	}
	return <Loader />
}

export default ContentController;