import './assets/styles/App.scss';


import React from 'react';
import { Switch, Route, useLocation, useEffect } from "react-router-dom";
import ContentController  from "./components/controller.content";
import Menu  from "./components/controller.menu";
import { TransitionGroup, CSSTransition} from "react-transition-group";


const routes = [
  {
    path: "/",
    exact: true,
    component: ContentController
  },
  {
    path: "/*",
    component: ContentController
  }
];


function App() {
	const location = useLocation();
    const timeout = { enter:300, exit: 5 };
	const cssFix = () => {
	    let root = document.documentElement;
	    root.style.setProperty('--vh', window.innerHeight+"px");
	}
	window.addEventListener('resize', cssFix);
	cssFix();
	/*
	React.useEffect(() => {
		
		function handleResize() {
			console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)		
		}
		window.addEventListener('resize', handleResize)
	})
*/


	// const nodeRef = React.useRef(null);
	return (
			<div className="App">
				<Menu/>
				<main>
				    <TransitionGroup className="content-wrapper">
						<CSSTransition 
							key={location.key} 
							classNames="trans" 
							timeout={timeout}
							mountOnEnter={true} unmountOnExit={true}
						>
							<div className="trans">
							<Switch location={location}>
					            {routes.map((route, index) => (
						            <Route
										key={index}
										path={route.path}
										exact={route.exact}
										component={route.component}
									/>	              
					            ))}
							</Switch>
							</div>
					    </CSSTransition>
					</TransitionGroup>
				</main>
			</div>
	);
}
export default App;

